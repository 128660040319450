import { Component, OnInit , Output, Input, EventEmitter } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { FormGroup, FormBuilder, FormsModule, Validators } from '@angular/forms'
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import Swal from 'sweetalert2';
import { HttpHeaders } from '@angular/common/http';
import { Directive, HostListener} from '@angular/core'

import { CartService } from '../../Services/cart.service';
import { Router, ActivatedRoute } from '@angular/router';
//import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss'],
    providers: [MessageService],
})
export class ProductDetailsComponent implements OnInit {
    alphabetvale: any; 
    HomeUrl: any;
    enquirydata: any;
    categoryId: any;
    ProductListforFilter: any = []
    ProductsList: any = []
    public form: FormGroup;
    personName: any;
    contactNumber: any;
    email: any;
    comments: any;
    cartitems = false;
    cartData = [];
    enquiryData = [];
    pInfocartData: any
    cartItemsCount: any;
    ItemId: number;
    itemSize: string;
    selectedItemforCart: any = []
    cartItemList: any = []
    toast: any;
    P_ID: any;
    cartItemList1: any[];
    whatsAppProductURL: string;
    token: any;
    arr: any[];
    selectedcompanys: any;
    selectedlocation: any;
    selectedexperince: any;
    searchcompany: any = [];
    searchcomp: any;
    JobCode: any;
  searchlist: any = [];
  Companyname: any;
    pInfocartData1: any;
    enquirydata1: any;
    originalCategory: any;
    categoryName: string;
    activeCategoryID: number;
    filteredProducts: any;
    activeCategoryname: any;
    category: any;
    filteredProductCount: any;
    isGridView: boolean;
    Categories: any;

    constructor(fb: FormBuilder, private route: ActivatedRoute, public generalService: GeneralService, public cartService: CartService, public http: HttpClient, public router: Router, ) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        debugger
        this.pInfocartData1 = localStorage.getItem('cartData')
        this.pInfocartData = JSON.parse(this.pInfocartData1)
        this.enquirydata1 = localStorage.getItem('enquiryData')
        this.enquirydata = JSON.parse(this.enquirydata1)
        if (this.pInfocartData != null) {
            this.cartData = this.pInfocartData
        }

        this.form = fb.group({
            'personName': ['', Validators.compose([Validators.required])],
            'contactNumber': ['', Validators.compose([Validators.required])],
            'comments': [''],
            'email': ['', Validators.compose([Validators.required])],
        });
        this.personName = this.form.controls['personName'];
        this.contactNumber = this.form.controls['contactNumber'];
        this.comments = this.form.controls['comments'];
        this.email = this.form.controls['email'];
        if (this.enquirydata != null) {
            this.form.controls['personName'].setValue(this.enquirydata[0].PersonName);
            this.form.controls['contactNumber'].setValue(this.enquirydata[0].PhoneNumber);
            this.form.controls['email'].setValue(this.enquirydata[0].MailId);
        }
        console.log(this.pInfocartData)

        var catdata = localStorage.getItem('catid')
        if (catdata != null) {
            //this.categoryId = catdata
           // this.FilterList(catdata);

        }
        else {
            //this.categoryId = 1;
           //this.FilterList(1);
        }
        localStorage.removeItem('catid');
    }

    ngOnInit() {
        debugger
        this.GetCategories();

        this.route.queryParams.subscribe(params => {
            const categoryID = +params['categoryID']; // Convert to number
            if (!isNaN(categoryID)) {
                this.GetProductsBasedOnCategoryID(categoryID);
            }
        });

        // Set activeCategoryID directly here
        const initialCategoryID = +this.route.snapshot.queryParams['categoryID'];
        if (!isNaN(initialCategoryID)) {
            this.activeCategoryID = initialCategoryID;
        }
        const initialCategorynam = +this.route.snapshot.queryParams['CategoryName'];
        if (!isNaN(initialCategorynam)) {
            this.activeCategoryname = initialCategorynam;
        }
        //this.GetCategories();

        this.cartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/
            this.cartItemsCount = res.length;
            this.cartItemList = res;
        })
        /// this.FilterList(1)
        // this.categoryId=1
    }

    getCategoryName(categoryID: number): string {
        const category = this.originalCategory.find(item => item.CategoryID === categoryID);
        const categoryName = category ? category.CategoryName : ''; // Get category name if found, otherwise empty string
        localStorage.setItem("cat", categoryName); // Store the categoryName in local storage
        return categoryName; // Return the category name
    }
    //FilterList(id) {
    //    debugger
    //    if (id == 1) {
    //        this.categoryId = 1
    //        this.GetProductsBasedOnCategoryID(id)
    //    }
    //    else if (id == 2) {
    //        this.categoryId = 2
    //        this.GetProductsBasedOnCategoryID(id)
    //    }
    //    else if (id == 3) {
    //        this.categoryId = 3
    //        this.GetProductsBasedOnCategoryID(id)
    //    }
    //    else if (id == 4) {
    //        this.categoryId = 4
    //        this.GetProductsBasedOnCategoryID(id)
    //    }
    //    else {
    //        this.categoryId = 5
    //        this.GetProductsBasedOnCategoryID(id)
    //    }
    //}

    GetProductsBasedOnCategoryID(Id) {
        debugger
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            var CategoryId = Id
            var UploadFile = new FormData();
            UploadFile.append("CategoryID", CategoryId);
            var url = this.HomeUrl + "api/SCIKEM/GettingProductDetailsBasedOnCategoryID";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });
            //var url = 'api/BookmyLawyer/Get_Exceptions';
            //this.generalService.PostData(url, formData).then((data: any) => {
            debugger
            this.http.post(url, UploadFile, { headers }).subscribe((data: any) => {

                debugger
                this.searchcompany = data
                this.ProductListforFilter = data


            }, err => {
                //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
                // this.spinner.hide();
            });
        }); // Added a closing bracket for the outermost function
    }



  ProductInfo(CategoryName, ChemicalName, Cas) {
    debugger
    // Remove any existing data from local storage
    //localStorage.removeItem('Cattegoryid');
    //localStorage.removeItem("Cas");
    //localStorage.removeItem("CategoryName");

    // Store the new data in local storage
    localStorage.setItem("ChemicalName", ChemicalName)
    localStorage.setItem("CategoryName", CategoryName)
    localStorage.setItem("Cas", Cas)
    localStorage.setItem("cartData", JSON.stringify(this.cartData))
    localStorage.setItem("Cattegoryid", this.categoryId);

    // Navigate to the product information page in the current window
    this.router.navigate(['/Products', CategoryName, ChemicalName + '-' + Cas]);


     ///Open the product information page in a new window
    const url = 'https://Scikem.com/Products/' + CategoryName +'/' +ChemicalName+'-'+Cas ;
    //window.open(url, '_blank',);
  }



    countReturn() {
      
        this.cartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/

            this.cartItemList = res.length;
            return this.cartItemList
        })
        return this.cartItemList
    }
    Addtocart(item: any) {
        debugger
        //this.cartItemList1 = [];

        this.cartService.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/
            for (var i = 0; i < res.length; i++) {
                this.P_ID = res[i].P_ID
            }
            //this.P_ID = res[0].P_ID;
            //this.P_ID = this.cartItemList1[0].P_ID
            return this.cartItemList1

        })
        this.Addtocart1(item);


    }


    GetCategories() {

        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({

            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = this.HomeUrl + "api/SCIKEM/Categories_CRUD";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(
                (data: any) => {

                    this.Categories = data;

                },
                (err) => {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }

    getFilteredProductCount(categoryID: any): number {

        return this.Categories.filter(product => product.CategoryID === categoryID).length;
    }


    navigatingToSearchedProduct(Productname: any) {

        this.router.navigate(['/Product/' + Productname]);
    }


    Addtocart1(item: any) {

        debugger

        // this.cartItemList1 = this.cartItemList;

        //for (var i = 0; i < this.cartItemList1.length; i++) {
        // this.P_ID = this.cartItemList1[0].P_ID
        // }
        if (this.P_ID != item.P_ID) {
            debugger
            this.ItemId = 0;
            this.itemSize = "";
            this.selectedItemforCart.push({
                P_ID: item.P_ID, Catalog_No: item.Catalog_No, ChemicalName: item.ChemicalName ? item.ChemicalName : "",
                CatChemicalName: item.CatChemicalName,
                Cas: item.Cas,
                Pack1: item.Pack1,
                Price1: item.Price1,
                Pack2: item.Pack2,
                Price2: item.Price2,
                Pack3: item.Pack3,
                Price3: item.Price3,
                Pack4: item.Pack4,
                Price4: item.Price4,
                Pack5: item.Pack5,
                Price5: item.Price5,
                Pack6: item.Pack6,
                Price6: item.Price6,
                Formula: item.Formula,
                Structure: item.Structure,
                MolecularWeight: item.MolecularWeight,
                MeltingPoint: item.MeltingPoint,
                BoilingPoint: item.BoilingPoint,
                Synonym: item.Synonym,
                Apiname: item.Apiname,
                Storage: item.Storage,
                Density: item.Density,
                PhysicalState: item.PhysicalState,
                MoistureContent: item.MoistureContent,
            })

            this.cartService.addtoCart(this.selectedItemforCart);
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Added to Cart Successfully' });
        }
        this.toast.add({ severity: 'warn', summary: 'Warning', life: 1000, detail: 'Product already exist in cart' });

    }

 

    filterTests() {
        debugger
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        let filtered: any[] = [];
        var UploadFile = new FormData()
        UploadFile.append("Param", this.selectedcompanys)

        var url = "api/SCIKEM/Search_Products"
        this.generalService.PostData(url, UploadFile).then(data => {
            this.searchcompany = data
        }, err => {
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
    }
    clearItem(autocomplete: any) {
        this.searchlist = [];
        debugger
        autocomplete.value = '';
        this.filterTests();
        window.location.reload();
        //this.searchlist = this.searchcompany;
    }
    filter(event) {   
           debugger
        let query = event.detail.value;

        this.searchlist = [];

        this.searchlist = this.searchcompany.filter((KR) => {
            return (
                KR.ChemicalName.toLowerCase().indexOf(query.toLowerCase()) > -1);

        })      

    }
    search(ev) {
        debugger
        if (ev) {
            this.searchcompany = this.searchlist.filter(a => a.ChemicalName == ev.ChemicalName);
        }
        else {
            this.searchcompany = this.searchlist

        }
    }

    keyUp(ev) {
        debugger
        this.searchcompany = this.searchlist.filter((item) => {
            return item.ChemicalName.toLowerCase().startsWith(ev.target.value.toLowerCase())


        });
  }
 


 }
