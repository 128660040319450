
<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="banner_heading">Contact Now</div>
                    <nav aria-label="breadcrumb">
                        <a href="/home"style="color:white;">Home >> </a>
                        <a href=""style="color:white;">Contact Now</a>
                    </nav>
                </div>
            </div>
        </div>
    </nav>






    <!--<div class="container-fluid banner_image mobile_bread">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="banner_heading">Contact Now</div>
            <nav aria-label="breadcrumb">
              <a href="/home">Home >> </a>
              <a href="">Contact Now</a>
            </nav>
          </div>
        </div>
      </div>
    </div>-->

    <div class="container bnr">

    </div>


    <div id="contact" class="contact-area container-fluid content_image" style="color:black">
        <div class="container" style="color:black">

            <div class="row" style="padding-top:4rem;">
                <div class="col-lg-5 col-md-12 forms" style="color:black ">
                    <div class="contact-info">
                        <div style=" font-size: 21px;font-weight: bold;padding-bottom: 2rem;color:black">Contact Now</div>
                        <div class="d-table">

                            <div>
                                <ul style="color:black;list-style-type:none;">
                                    <li style="color:black">
                                        <i class="fas fa-map-marker-alt"></i> <span>Reach Us:</span>
                                        Scikem Pharmaceuticals  Pvt. Ltd.<br /> H.No: 11-6-24/5, Near Substation, Opp: IDPL Main Gate Balanagar, Kukatpally, Hyderabad-500042.

                                    </li>
                                    <li style="color:black"><i class="far fa-envelope"></i> <a href="mailto:rajireddy.akiti@scikem.com"><span>Email:</span>rajireddy.akiti@scikem.com</a></li>
                                    <li style="color:black"><i class="fas fa-phone-alt"></i> <a href="tel:+919959455992"><span>Call:</span>+91 9959455992</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12 forms1">
                    <div class="contact-form">


                        <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
                            <div class="row" style="color:black">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <label style="color:black">Name</label>
                                        <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                            <div *ngIf="name.errors.required" style="color:black">Name is required.</div>
                                            <div *ngIf="name.errors.minlength" style="color:black">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                            <div *ngIf="name.errors.maxlength" style="color:black">Name is maximum 50 character.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <label style="color:black">Email</label>
                                        <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                        <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <label style="color:black">Subject</label>
                                        <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject">
                                        <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Phone subject is required.</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group mb-3">
                                        <label style="color:black">Phone Number</label>
                                        <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                        <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <label style="color:black">Message</label>
                                        <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                        <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid" style="color:black">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>


            <div class="row">
                <div class="col-md-12 mt-5">

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.483207167492!2d78.47409087125428!3d17.484432263381617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9a832281e647%3A0x6d5ba6511ce47864!2sh%2C%206%2C%20Road%20No.%2011%2C%20Shankar%20Enclave%2C%20Old%20Bowenpally%2C%20Secunderabad%2C%20Telangana%20500015!5e0!3m2!1sen!2sin!4v1710923062433!5m2!1sen!2sin"  class="w-100"  height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>



        </div>
    </div>

   </div>
    
    
    <style>
        @media only screen and (max-width:600px) {


            .banner_image {
                background-image: url('assets/breadcrubg.jpg');
                background-attachment: initial;
                background-position: right;
                background-repeat: no-repeat;
                background-size: cover;
                margin-top: 2rem;
                height: 90px;
            }
        .forms{
            padding: 6%;
        }
            .forms1 {
                margin-top:5rem;
                padding: 6%;
            }
                .banner_image a {
                    color: white;
                    margin-left: 0%;
                    font-size: 10px;
                }

            .banner_heading {
                font-family: "Poppins";
                font-size: 13px;
                color: white;
                font-weight: 500;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                padding: 10px;
                margin-top: 11px;
                margin-left: -9px;
            }

            .body {
                overflow-x: hidden;
            }
        }
    </style>
