<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="banner_heading">Our Services</div>
                    <nav aria-label="breadcrumb">
                        <a href="/home" style="color:white;">Home >> </a>
                        <a href="" style="color:white;">Our Services</a>
                    </nav>
                </div>
            </div>
        </div>
    </nav>



    <!--<div class="container-fluid banner_image">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="banner_heading">FINE  CHEMICALS  AND PYRIDINE DERIVATIVES  SYNTHESIS</div>
            <nav aria-label="breadcrumb">
              <a href="/home"style="color:white;">Home >> </a>
              <a href=""style="color:white;">Fine Chemicals And Pyridine Derivatives Synthesis</a>
            </nav>
          </div>
        </div>
      </div>
    </div>-->

    <div class="container-fluid content_image">
        <div class="container">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mt-5">
                        <h2>Our Services</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-3">
                        <p>
                            With its advanced technical proficiency in chemistry, <strong>Scikem stands at the forefront of innovation, offering a wide range of solutions and services</strong> to its clientele. From contract research to<strong> CMO intermediates, specialty molecules, amino acids, API impurities, API intermediates, and various other products</strong>, we cover a broad spectrum of needs, ranging from small-scale to large-scale operations. At the heart of our approach lies a commitment to <strong>customer satisfaction</strong>, ensuring that we deliver <strong>top-quality</strong> products at competitive prices.
                        </p>
                        <p>
                            We prioritize transparency and open communication, encouraging our clients to stay connected with us for any business-related inquiries or requirements. <strong>Our goal is to foster lasting partnerships built on trust and reliability, as we strive to meet and exceed our customers' expectations</strong>. Whether you're seeking specialized chemical solutions or assistance with a specific project, Scikem is here to provide tailored support and expertise every step of the way.
                        </p>
                    </div>
                    <div class="col-lg-6 mt-5">
                         
                        <img src="assets/Scikem/banner3.webp" />
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
    <style>
        @media only screen and (max-width:600px) {
            .banner_image {
                background-image: url('assets/breadcrubg.jpg');
                background-attachment: initial;
                background-position: right;
                background-repeat: no-repeat;
                background-size: cover;
                margin-top: 2rem;
                height: 90px;
            }

                .banner_image a {
                    color: white;
                    margin-left: 0%;
                    font-size: 10px;
                }

            .banner_heading {
                font-family: "Poppins";
                font-size: 13px;
                color: white;
                font-weight: 500;
                letter-spacing: 1.5px;
                text-transform: uppercase;
                padding: 10px;
                margin-top: 11px;
                margin-left: -9px;
            }
            h2 {
                margin-left: -9px;
            }
            img {
                margin-left: -10px;
            }
        }
    </style>
