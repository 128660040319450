<div class="topnav">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 topnav1">
                <i class="fa fa-fw fa-phone">  </i>
                <a href="tel:+919959455992" target="_blank" style="color:white;font-size:smaller;cursor:pointer;">
                    +91 9959455992
                </a>
                <a href="tel:+919177607659" target="_blank" style="color:white; font-size:smaller;cursor:pointer;">
                    +91 9177607659
                </a>
            </div>
            <div class="col-lg-5 col-12 ">
                <ul>
                    <li class="nav-item">
                        <div class="nav-link">
                            <p-autoComplete style="" placeholder="Search CAS/Chemical Name...!" [(ngModel)]="selectedProduct" [suggestions]="searchprods" (completeMethod)="filterProducts($event)" field="ChemicalName" [dropdown]="false">
                                <ng-template let-country pTemplate="item">
                                    <div class="country-item card" (click)="navigatingToSearchedProduct(country)">
                                        <div class="">
                                            <h6 >
                                                {{country.Catalog_No}}&nbsp;|&nbsp;{{country.Cas}}&nbsp;|&nbsp;{{country.ChemicalName}}
                                            </h6>
                                            <br />
                                        </div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-lg-4 topnav1" style="text-align:end;">
                <a class="" href="rajireddy.akiti@scikem.com" target="_blank" style="color: white; cursor: pointer; ">
                    <i class="fa fa-envelope"
                       aria-hidden="true"></i> rajireddy.akiti@scikem.com
                </a>
                <a href="https://api.whatsapp.com/send?phone=+919959455992&text=&source=&data=" target="_blank" style="color:white;">
                    <i class="fab fa-whatsapp"></i>
                </a>
                <a href="https://www.facebook.com/YourPageURL" target="_blank" style="color: white;">
                    <i class="fab fa-facebook-square"></i>
                </a>
                <a href="https://twitter.com/YourTwitterHandle" target="_blank" style="color: white;">
                    <i class="fab fa-twitter-square"></i>
                </a>
            </div>
        </div>
    </div>
</div>
<nav class="navbar navbar-expand-lg navbar-light bg-light"
     [class.active]="classApplied">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <!--<span>Dress</span>Box-->
            <img src="../../../../assets/Scikem/Logo_scikem.png" alt="Logo_scikem" class="dresslogo" />
            <!--<img src="../../../../assets/Scikem/logo.webp" class="dresslogo" />-->
        </a>
        <div class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent"style="    margin: 1% 0% 0% 0%;">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" routerLink="/home">Home</span></li>
                <li class="nav-item">
                    <div class="dropdown">
                        <p class="dropbtn" style="cursor:pointer;">About Us <i class="fa fa-caret-down" style="color:black"></i></p>
                        <div class="dropdown-content">
                            <a href="/about-us" title="Learn more about Scikem">Company Profile</a>
                            <a href="/vision-mission">Vision & Mission </a>
                            <!--<a href="/healthy-safety">Leadership Team </a>-->

                        </div>
                    </div>
                </li>
                <!--<li class="nav-item">
        <div class="dropdown">
            <p class="dropbtn" style="cursor:pointer;">Products <i class="fa fa-caret-down" style="color:black"></i></p>
            <div class="dropdown-content">
                <a href="/Products">API Intermediates Products</a>
            </div>
        </div>
    </li>-->
                <li class="nav-item">
                    <div class="dropdown">
                        <div class="dropbtn" (mouseenter)="GetCategories()" style="color:black;cursor:pointer">
                            Products &nbsp;<i class="fa fa-caret-down"></i>
                            <div class="dropdown-content">
                                <div *ngFor="let link of Categories;">
                                    <a (click)="navigatingToSearchedProducts(link.CategoryID,link.CategoryName)">
                                        {{ link.CategoryName }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="nav-item" routerLink="/FineChemicals"><span class="nav-link">Services </span></li>

                <li class="nav-item" routerLink="/MedicinalorSyntheticChemistryServices"><span class="nav-link">Innovative Research </span></li>

                <li class="nav-item" routerLink="/APIIntermediates"><span class="nav-link">Manufacturing</span></li>


                <!--<li class="nav-item">
        <div class="dropdown">
            <p class="dropbtn" routerLink="/Login">Our services <i class="fas fa-caret-down" style="color:black"></i></p>
            <div class="dropdown-content">
                <a href="/MedicinalorSyntheticChemistryServices" title="Learn more about Medicinal or Synthetic Chemistry Services at Scikem">Medicinal & Synthetic Chemistry Services</a>
                <a href="/ProcessR&D" title="Learn more about Process R&D at Scikem">Process Development </a>
                <a href="/APIIntermediates" title="Learn more about API Intermediates at Scikem">API Intermediates</a>
                <a href="/FineChemicals" title="Learn more about Fine Chemicals at Scikem">Fine Chemicals And Pyridine Derivatives Synthesis</a>
            </div>
        </div>
    </li>-->
                <!--  <li class="nav-item" routerLink="/Capabilities"><span class="nav-link">Capabilities</span></li>-->
                <li class="nav-item" routerLink="/ContactUs"><span class="nav-link">Contact Now</span></li>
            </ul>

            <ul class="navbar-nav ml-auto for-responsive">

                <li class="nav-item"><span class="nav-link" routerLink="/home">Home</span></li>
                <li class="nav-item">
                    <div class="dropdown">
                        <p class="dropbtn" style="cursor:pointer;">About Us <i class="fa fa-caret-down" style="color:black"></i></p>
                        <div class="dropdown-content">
                            <a href="/about-us" title="Learn more about Scikem">Company Profile</a>
                            <a href="/vision-mission">Vision & Mission With Core Values</a>
                            <a href="/healthy-safety"> Leadership Team</a>

                        </div>
                    </div>
                </li>
                <li class="nav-item">
                    <div class="dropdown">
                        <p class="dropbtn"style="cursor:pointer;">Products <i class="fa fa-caret-down" style="color:black"></i></p>
                        <div class="dropdown-content">
                            <a href="/Products">API Intermediates Products</a>
                        </div>
                    </div>
                </li>
                <li class="nav-item" routerLink="/FineChemicals"><span class="nav-link">Services </span></li>

                <li class="nav-item" routerLink="/MedicinalorSyntheticChemistryServices"><span class="nav-link">innovative research </span></li>

                <li class="nav-item" routerLink="/APIIntermediates"><span class="nav-link">Manufacturing</span></li>

                <!--<li class="nav-item">
        <div class="dropdown">
            <p class="dropbtn" routerLink="/Login">Our services <i class="fas fa-caret-down" style="color:black"></i></p>
            <div class="dropdown-content">
                <a href="/MedicinalorSyntheticChemistryServices" title="Learn more about Medicinal or Synthetic Chemistry Services at Scikem">Medicinal & Synthetic Chemistry Services</a>
                <a href="/ProcessR&D" title="Learn more about Process R&D at Scikem">Process Development </a>
                <a href="/APIIntermediates" title="Learn more about API Intermediates at Scikem">API Intermediates</a>
                <a href="/FineChemicals" title="Learn more about Fine Chemicals at Scikem">Fine Chemicals And Pyridine Derivatives Synthesis</a>
            </div>
        </div>
    </li>-->
                <!--   <li class="nav-item" routerLink="/Capabilities"><span class="nav-link">Capabilities</span></li>-->
                <li class="nav-item" routerLink="/ContactUs"><span class="nav-link">Contact Us</span></li>

            </ul>
        </div>
    </div>
</nav>
<style>
  
    input, button, select, optgroup, textarea {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        width: 425px !important;
    }
</style>