<div id="about" class="about-area pt-5" >
    <div class="container-fluid">



        <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="../../../../assets/Scikem/banner3.webp" alt="2" />
                    <span class="name">Quality Chemistry Made Affordable</span>
                </div>
                <div class="carousel-item">
                    <img src="../../../../assets/Scikem/banner2.webp" class="banner2" alt="banner2" />
                    <span class="name">Discovering And Driving Science..</span>
                </div>
                <div class="carousel-item">
                    <img src="../../../../assets/Scikem/2.webp" class="banner2" alt="2" />
                </div>
                <div class="carousel-item">
                    <img src="../../../../assets/Scikem/3.webp" class="banner2" alt="3" />
                </div>

                <div class="carousel-item">
                    <img src="../../../../assets/Scikem/banner.webp" class="banner2" alt="banner" />
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>






        <!--<div id="demo" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div style="position: relative;">
                        <img src="../../../../assets/Scikem/banner3.webp" alt="2" />
                        <span class="name">Quality Chemistry Made Affordable</span>
                    </div>
                </div>

                <div class="carousel-item ">
                    <img src="../../../../assets/Scikem/banner2.webp" class="banner2" alt="banner2" />
                    <span class="name">Discovering And Driving Science..</span>
                </div>


                <div class="carousel-item ">
                    <img src="../../../../assets/Scikem/2.webp" class="banner2" alt="2" />
                </div>

                <div class="carousel-item">
                    <img src="../../../../assets/Scikem/3.webp" class="banner2" alt="3" />
                </div>

                <div class="carousel-item">
                    <img src="../../../../assets/Scikem/banner.webp" class="banner2" alt="banner" />
                </div>

                <div class="carousel-item ">
                <img src="../../../../assets/Scikem/4.webp" class="banner2" alt="4" />
                <span class="name">Quality Chemistry Made Affordable</span>
            </div> 
            </div>
        </div>
        <a class="carousel-control-prev controler" href="#demo" data-slide="prev">
            <span class="carousel-control-prev-icon"></span>
        </a>
        <a class="carousel-control-next controler" href="#demo" data-slide="next">
            <span class="carousel-control-next-icon"></span>
        </a>
    </div>-->


    <div class="container-fluid bg_1" style="background-image: url('../../../../assets/img/new imgs/banners/bg_123.webp'); ">
        <div class="container">
            <div class="section-title">
                <h1>About Scikem</h1>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="row os-animation animated fadeInUp" data-os-animation="fadeInUp" data-os-animation-delay="0.3s" style="animation-delay: 0.3s;">
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <img src="assets/Scikem/banner2.webp" alt="banner2"/>
                </div>
                <div class="col-lg-6 card mobilecard">
                    <div class="">
                        <p>
                            Scikem is one of the emerging pharmaceutical company. Scikem is a value-based company with Innovation, Research and technology, located in Hyderabad, the India's bulk drug capital.
                        </p>
                        <p>
                            At Scikem, we recognize the importance of leveraging the latest research and technology to address the evolving needs of healthcare worldwide. Through our state-of-the-art facilities and collaborative partnerships, we continue to drive innovation and make significant contributions to the global pharmaceutical landscape.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>



    <style>
        .name {
            position: absolute;
            top: 38%;
            left: 33%;
            transform: translate(-50%, -50%);
            color: #55038e;
            font-size: 36px;
            font-weight: bold;
        }
        .single-box {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            height: 32rem;
            border: 1px solid rgba(10, 116, 148, 0.16);
            border-radius: 20px;
        }

            .single-box:hover {
                background-color: #0baddf;
                color: #fff;
            }
    </style>
