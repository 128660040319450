<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="banner_heading">Innovative research</div>
                    <nav aria-label="breadcrumb">
                        <a href="/home" style="color:white;">Home >> </a>
                        <a href="" style="color:white;"> Innovative research</a>
                    </nav>
                </div>
            </div>
        </div>
    </nav>




    <!--<div class="container-fluid banner_image">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="banner_heading">MEDICINAL & SYNTHETIC CHEMISTRY SERVICES</div>
            <nav aria-label="breadcrumb">
              <a href="/home">Home >> </a>
              <a href="">Medicinal & Synthetic Chemistry Services</a>
            </nav>
          </div>
        </div>
      </div>
    </div>-->

    <div class="container-fluid">
        <div class="container">
            <div class="row mt-5">
                <div class="col-lg-12">
                    <h2>Innovative research</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 mt-5">
                    <img src="assets/Scikem/innovation.webp" />
                </div>
                <div class="col-lg-6">
                    <p>
                        <strong>Research and development</strong> serve as the cornerstone for the success of any organization. At Scikem, we recognize the pivotal role of R&D in driving progress and achieving excellence. With a relentless focus on innovation, we continuously develop and refine cutting-edge research methodologies, leveraging scientific expertise and adopting novel approaches. Our commitment to pushing the boundaries of knowledge and discovery enables us to deliver breakthrough solutions that are both affordable and of the <strong>highest quality</strong>.
                    </p>
                    <p>
                        Through strategic collaboration and a culture of continuous learning, we harness the collective intelligence of our team to tackle complex challenges and pioneer new frontiers. At Scikem,<strong> innovation is not just a buzzword; it's ingrained</strong> in our DNA. We are dedicated to fostering an environment where creativity thrives and where every idea, no matter how unconventional, is given the opportunity to flourish. Together, we strive to redefine industry standards and make significant contributions to scientific advancement and societal well-being.
                    </p>
                </div>
            </div>
        </div>
    </div>

</div>


    <style>
        @media only screen and (max-width:600px) {
            .banner_image {
                background-image: url('assets/breadcrubg.jpg');
                background-attachment: initial;
                background-position: right;
                background-repeat: no-repeat;
                background-size: cover;
                margin-top: 2rem;
                height: 90px;
            }

                .banner_image a {
                    color: white;
                    margin-left: 0%;
                    font-size: 10px;
                }

            .banner_heading {
                font-family: "Poppins";
                font-size: 13px;
                color: white;
                font-weight: 500;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                padding: 10px;
                margin-top: 11px;
                margin-left: -8px;
            }
            }

            .body {
                overflow-x: hidden;
            }
        }
    </style>
