<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="banner_heading">COMPANY PROFILE</div>
                    <nav aria-label="breadcrumb">
                        <a href="/home" style="color:white;">Home >> </a>
                        <a href="" style="color:white;">About Us</a>
                    </nav>
                </div>
            </div>


 
        </div>
    </nav>


    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 mt-4">
                    <h2>About Us</h2>
                </div>

                <div class="col-lg-12">
                    <p>
                        Scikem stands at the forefront of the pharmaceutical industry as an emerging leader, driven by its unwavering commitment to <strong>innovation, research</strong>, and cutting-edge technology. Headquartered in Hyderabad, India's esteemed bulk drug capital, Scikem epitomizes a <strong>value-based approach</strong> to pharmaceutical excellence.
                    </p>
                    <p>
                        With a focus on pioneering advancements and breakthrough discoveries, Scikem is dedicated to pushing the boundaries of <strong>scientific innovation</strong>. Our relentless pursuit of <strong>excellence</strong> and dedication to<strong> quality assurance</strong> ensure that every product we deliver meets the highest standards of <strong>efficacy</strong> and <strong>safety</strong>.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-5 mt-4">
                    <img src="assets/Scikem/aboutvission.webp" />
                </div>
                <div class="col-lg-7">
                    <p>
                        At <strong>Scikem</strong>, we recognize the importance of leveraging the latest <strong>research and technology</strong> to address the evolving needs of healthcare worldwide. Through our state-of-the-art facilities and collaborative partnerships, we continue to drive innovation and make significant contributions to the global pharmaceutical landscape.
                    </p>
                    <p>
                        As we embark on our journey of growth and expansion, Scikem remains steadfast in its commitment to improving the quality of life for individuals around the world. We are proud to be recognized as a beacon of <strong>excellence in the pharmaceutical industry</strong>, delivering value-driven solutions that make a meaningful difference in people's lives.
                    </p>
                </div>

            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 mt-4">
                <h2>our Leadership Team </h2>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-4">
                <figure>
                    <img src="assets/Scikem/RAJIREDDYAKITI,.webp" />
                    <figcaption class="mt-3 ml-2 "><h6>RAJIREDDY AKITI</h6></figcaption>
                </figure>

            </div>
            <div class="col-lg-8">
                <h4>
                    RAJIREDDY AKITI,
                    DIRECTOR- R&D
                </h4>
                <p>
                    He joined Scikem phearmaceuticals Pvt. Ltd. effective from <strong>April 1, 2017 as a Director- R&D</strong>.
                </p>
                <p>
                    He is a competet professional with over <strong>27 years of experience in Pharma industry</strong>. He is proficient in developing & streamlining processes to meet the operational goals within the cost, time & quality parameters.
                </p>
                <p>
                    As a researcher <strong>he has delivered targets for CRO, CMO, impurities, Carbhohydrate chemistry and other miscellaneous products</strong>. Before joining Scikem, Rajireddy was associated with "Sapala organics Pvt. Ltd." as a Senior research scientist, with Sai Advantium Pharma ltd., as an Associate Research scientist and as a Post-doctoral fellow in Japan.
                </p>
                <p>
                    He is engaged in the strategic planning, securing on time delivery of high quality materials, manufacture of high value low volume organic molecules, experimental design, optimization of synthesis and purification protocols on lab scale to kilo scale, including process chemistry. His specific interests include design, synthesis, and chemistry of novel carbohydrates, heterocyclic chemistry and application of modern synthetic tools in organic synthesis.
                </p>
                <p>
                    He has experience in everything from manufacture of process R&D, custom synthesis, technology assessment, technology development and technology transfer. He's also handled many contract synthesis projects, designed and executed multi-step synthesis of molecules on a commercial production scale, and is known for his cost-effective synthesis of various intermediates
                </p>
            </div>
        </div>
        <hr class="mt-5" style="border: 0.5px solid #ebe6e6;" />
        <div class="row mt-5">

            <div class="col-lg-8">
                <h4>
                    Dr. PRADEEP DEVULAPALLI,
                    DIRECTOR-OPERATIONS
                </h4>
                <p>
                    He joined Scikem pharmaceuticals Pvt. Ltd. effective from <strong>April 1, 2017 as a Director-Operations</strong>.
                </p>
                <p>
                    <strong>He is a scientist holding a doctoral degree from Texas Christian University, Texas, USA</strong>. He has a vast experience of over two decades as a researcher and has publications in National and International journals, presented papers at various international conferences.
                </p>

                <p>
                    He plays a key role in making business strategies and strengthening the client relationship to facilitate the growth and development of the company. <strong>After his doctoral degree, he worked as post doctoral fellow at Southern Methodist University, Dallas, TX, USA</strong>. He also <strong>worked as a Research Scientist in "Sapala Organics Pvt. Ltd" Hyderabad</strong>.
                </p>
              
            </div>
            <div class="col-lg-4">
                <figure>
                    <img src="assets/Scikem/Dr.PRADEEP DEVULAPALLI.webp" class="fig" />
                    <figcaption class="mr-5 mt-3 fig"><h6>Dr. Pradeep Devulapalli</h6></figcaption>
                </figure>


            </div>
        </div>
    </div>


</div>
    <style>
        @media only screen and (max-width:600px) {
            .banner_image {
                background-image: url('assets/breadcrubg.jpg');
                background-attachment: initial;
                background-position: right;
                background-repeat: no-repeat;
                background-size: cover;
                margin-top: 2rem;
                height: 90px;
            }

                .banner_image a {
                    color: white;
                    margin-left: 0%;
                }

            .body {
                overflow-x: hidden;
            }
        }
    </style>
