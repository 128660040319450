
<meta charset="utf-8">

<base href="/">
<meta name="viewport" content="width=device-width, initial-scale=1">
<meta name="description" content="Scikem">
<meta property="og:title" content="Scikem">
<meta property="og:type" content="website">
<meta property="og:url" content="https://Scikem.com/#/">
<meta property="og:description" content="Scikem">
<!-- Specify a valid og:image URL -->
<meta property="og:image" content="https://Scikem.com/../../assets/ABOUT_IMG.png">


<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="banner_heading">Products</div>
                    <nav aria-label="breadcrumb">
                        <a href="/" style="color:white;">Home>></a>
                        <a class="breadcrumb-item" href="/Products" style="color:white;">Products>></a>
                        <a  aria-current="page" style="color:white;">API Intermediates</a>
                        <!--<a *ngIf="categoryId==4" aria-current="page" style="color:white">Pyridine Derivative</a>
                        <a *ngIf="categoryId==5" aria-current="page" style="color:white">Fine & Speciality Chemicals</a>-->
                    </nav>
                </div>
            </div>
        </div>
    </nav>



    <div class="container-fluid">
        <!--<div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="banner_heading ">MEDICINAL & SYNTHETIC CHEMISTRY SERVICES</div>
                <nav aria-label="breadcrumb ">
                    <a href="/home">Home >></a>
                    <a (click)="FilterList(1)" href="/Products" style="width: 82px;cursor:pointer;color:white">Products >></a>
                    <a *ngIf="CatID==1" (click)="FilterList(1)" routerLink="/Products" style="width: 135px;cursor:pointer;color:white">API Intermediates</a>
                    <a *ngIf="CatID==2" (click)="FilterList(2)" routerLink="/Products" style="width: 125px;cursor:pointer;color:white">Building Blocks</a>
                    <a *ngIf="CatID==3" (click)="FilterList(3)" routerLink="/Products" style="width: 155px;cursor:pointer;color:white">Aliphatic Compounds</a>
                    <a *ngIf="CatID==4" (click)="FilterList(4)" routerLink="/Products" style="width: 125px;cursor:pointer;color:white">Pyridine Derivative</a>
                    <a *ngIf="CatID==5" (click)="FilterList(5)" routerLink="/Products" style="width: 155px;cursor:pointer;color:white">Fine & speciality Chemicals</a>
                </nav>
              </div>
            </div>
          </div>-->
    </div>

    
    <div class="container mobileview">
        <div class="row">
            <!--<p class="mobile-mail">sales@Scikem.com</p>-->



            <div class="col-lg-4">
                <div class="prodd">PRODUCT DETAILS</div>
                <div class="shadow centered">
                    <div class="row" *ngFor="let item of Specdata">
                        <div class="col-lg-6 col-6">

                            <div *ngIf="item.Structure != null ||item.Structure != '' ">
                                <img src="{{HomeUrl+item.Structure}}" class="pro_img productimage" alt="Scikem" *ngIf="item.Structure">
                            </div>
                            <div *ngIf=" item.Structure == null || item.Structure == '' ">
                                <img src="../../../assets/logoScikem1.png" class="pro_img " alt="Scikem">
                            </div>
                            <img src="../../../assets/img/new imgs/banners/alt_logo-1.png" class="mobile_logo" />

                        </div>
                        <div class="col-lg-6 col-6">
                                    <img src="../../../assets/Scikem/logo.png"  />
                                    <h6 class="mail">Sales@scikem.com</h6>
                        </div>

                    </div>


                    <br />
                    <div class="row product_name">
                        <div class="col-lg-12 col-12">
                            <h4 class="" style="color: #0211a0; font-size: 20px; word-break: break-all;">{{Specdata[0].ChemicalName}}</h4>
                        </div>

                    </div>
                    <br />
                    <div class="row Catlog" *ngIf="Catalog_No!=''">

                        <div *ngIf="Catalog_No!=''" class="product_bg">
                            <div class="row">

                                <div class="col-lg-5 col-xs-6 col-5" *ngIf="Catalog_No!=''">
                                    <h3 class="aboutFont">Product Code</h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class=" casn" *ngIf="Catalog_No!=''">
                                        {{Catalog_No}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="Cas!=''" class="product_bg">
                            <div class="row">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">CAS Number</h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class=" casn" *ngIf="Cas!=''">
                                        {{Cas}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="Synonym!=''" class="product_bg">
                            <div class="row">
                                <div class="col-lg-5 col-xs-5 col-6">
                                    <h3 class="aboutFont">Synonym </h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-7">
                                    <div class="casn" *ngIf="Synonym!=''">
                                        {{Synonym}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="HSCode!=''" class="product_bg">
                            <div class="row ">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">HSCode</h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="HSCode!=''">
                                        {{HSCode}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="ModelNumber!=''" class="product_bg">
                            <div class="row">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">MDL Number</h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="ModelNumber!=''">
                                        {{ModelNumber}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="MolecularFormula!=''" class="product_bg">
                            <div class="row">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">Molecular Formula </h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="MolecularFormula!=''">
                                        {{MolecularFormula}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="MolecularWeight!=''" class="product_bg">
                            <div class="row ">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">Molecular Weight </h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="MolecularWeight!=''">
                                        {{MolecularWeight}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="Purity!=''" class="product_bg">
                            <div class="row ">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">Purity </h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="Purity!=''">
                                        {{Purity}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="PhysicalState!=''" class="product_bg">
                            <div class="row">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">Physical State</h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-6 col-xs-6 col-7">
                                    <div class=" casn" *ngIf="PhysicalState!=''">
                                        {{PhysicalState}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="MeltingPoint!=''" class="product_bg">
                            <div class="row ">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">Melting Point </h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="MeltingPoint!=''">
                                        {{MeltingPoint}}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="BoilingPoint!=''" class="product_bg">
                            <div class="row ">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">Boiling Point </h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="BoilingPoint!=''">
                                        {{BoilingPoint}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="Storage!=''" class="product_bg">
                            <div class="row ">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">Storage </h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-6 col-xs-6 col-7">
                                    <div class="casn" *ngIf="Storage!=''">
                                        {{Storage}}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="Apiname!=''" class="product_bg">
                            <div class="row ">
                                <div class="col-lg-5 col-xs-6 col-5" *ngIf="Apiname!=''">
                                    <h3 class="aboutFont">End Use </h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="Apiname!=''">
                                        {{Apiname}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="Density!=''" class="product_bg">
                            <div class="row">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">Density </h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="Density!=''">
                                        {{Density}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="Spectra!=''" class="product_bg">
                            <div class="row ">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">Spectra </h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="Spectra!=''">
                                        {{Spectra}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="SpecificGravity!=''" class="product_bg">
                            <div class="row ">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">SpecificGravity </h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="SpecificGravity!=''">
                                        {{SpecificGravity}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="FlashPoint!=''" class="product_bg">
                            <div class="row ">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">Flash Point </h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="FlashPoint!=''">
                                        {{FlashPoint}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="RefractiveIndex!=''" class="product_bg">
                            <div class="row ">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">Refractive Index </h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="RefractiveIndex!=''">
                                        {{RefractiveIndex}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="PharmaApplications!=''" class="product_bg">
                            <div class="row  ">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">Pharma Applications</h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="PharmaApplications!=''">
                                        {{PharmaApplications}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="OtherApplications!=''" class="product_bg">
                            <div class="row ">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">other Applications</h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="OtherApplications!=''">
                                        {{OtherApplications}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="AgroApplications!=''" class="product_bg">
                            <div class="row">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont">Agro Applications</h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="AgroApplications!=''">
                                        {{AgroApplications}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="OurStatus!=''" class="product_bg">
                            <div class="row">
                                <div class="col-lg-5 col-xs-6 col-5">
                                    <h3 class="aboutFont"> Our Status</h3>
                                    <a href="product-details.component.html"></a>
                                </div>
                                <div class="col-lg-5 col-xs-6 col-7">
                                    <div class="casn" *ngIf="OurStatus!=''">
                                        {{OurStatus}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="row">
                        <div class="col-lg-9 col-6 ml-n3">
                            <button (click)="toggleForm()">Enquiry</button>
                        </div>
                        <div class="col-lg-3 col-6 mt-3 ">

                            <div class="whatsapp">
                                <img src="../../../assets/whatsapp.jpg" class="icon_whatsapp" (click)="sendWhatsapp(Specdata[0].Structure)" />
                            </div>
                        </div>

                    </div>


                </div>
            </div>

            <div class="col-lg-4">
                <div class="prodd">Recommended Products</div>
                <div class="shadow1">

                    <div class="row">

                        <div class="col-lg-12 col-xs-6 border_product" *ngFor="let item of searchcompany | slice:0:4" style="cursor:pointer">
                            <div (click)="ProductInfo(item.CategoryName,item.ChemicalName,item.Cas)" class="border_product1" style="cursor:pointer;padding:10px">
                                <div class="row align-items-center">
                                    <!-- Updated -->
                                    <div class="col-lg-6 col-6">
                                        <!-- Updated -->
                                        <div class="image-container">
                                            <div *ngIf="item.Structure != null || item.Structure != ''">
                                                <img src="{{ HomeUrl + item.Structure }}" alt="Scikem" *ngIf="item.Structure" class="product_img">
                                            </div>
                                            <div *ngIf="item.Structure == null || item.Structure == ''" class="product_img">
                                                <img src="../../../assets/alt_logo-.png" alt="Scikem">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-6">
                                        <!-- Updated -->
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="text_size"> {{item.Catalog_No}}</div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="text_size"> {{item.Cas}}</div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="ChemicalName">
                                                    {{item.ChemicalName}}
                                                </div>
                                            </div>
                                            <div class="col-lg-6"></div>
                                            <div class="col-lg-6">
                                                <div class="details_btn">Details</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                      
                    </div>
                </div>
            </div>



            <div class="col-lg-4" *ngIf="showForm">
                <div class="prodd">PRODUCT ENQUIRY</div>

                <div class="contact-form">


                    <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
                        <div class="row">

                            <div class="form-group mb-3">
                                <label>Name</label>
                                <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                <div class="alert alert-danger" style=" height: 40px; padding: 10px;" *ngIf="name.touched && !name.valid">
                                    <div *ngIf="name.errors.required">Name is required.</div>
                                    <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                    <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                </div>

                            </div>

                            <div class="form-group mb-3">
                                <label>Company Name</label>
                                <input required ngModel name="Companyname" type="text" #Companyname="ngModel" class="form-control" id="Companyname">
                                <div class="alert alert-danger" style=" height: 40px; padding: 10px;" *ngIf="Companyname.touched && !Companyname.valid">Companyname is required.</div>

                            </div>

                            <div class="form-group mb-3">
                                <label>Email</label>
                                <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                <div class="alert alert-danger" style=" height: 40px; padding: 10px;" *ngIf="email.touched && !email.valid">Email Address is required.</div>

                            </div>


                            <div class="form-group mb-3">
                                <label>Phone Number</label>
                                <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                <div class="alert alert-danger" style=" height: 40px; padding: 10px;" *ngIf="number.touched && !number.valid">Phone number is required.</div>

                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Message</label>
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                    <div class="alert alert-danger" style=" height: 40px; padding: 10px;" *ngIf="message.touched && !name.valid">Message is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn22 " [class.disabled]="!contactForm.valid">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <br />

</div>

    <style>
        * {
            box-sizing: border-box;
        }

        .img-zoom-container {
            position: relative;
        }

        .img-zoom-lens {
            position: absolute;
            border: 1px solid #d4d4d4;
            width: 40px;
            height: 40px;
        }

        .img-zoom-result {
            border: 1px solid #d4d4d4;
            width: 300px;
            height: 300px;
        }


        tr.break td {
            height: 10px;
        }


        @media only screen and (max-width:600px) {

            .ddd{
                display: none;
            }
            .banner_image {
                background-image: url('assets/breadcrubg.jpg');
                background-attachment: initial;
                background-position: right;
                background-repeat: no-repeat;
                background-size: cover;
                margin-top: 3rem;
                height: 90px;
            }

                .banner_image a {
                    color: white;
                    margin-left: 4%;
                    font-size: 10px;
                }

            .banner_heading {
                font-family: "Poppins";
                font-size: 13px;
                color: white;
                font-weight: 500;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                padding: 10px;
                margin-top: 11px;
                margin-left:-9px;
            }

            .body {
                overflow-x: hidden;
            }
        }
    </style>

